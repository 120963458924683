/* -------
	RESET
-------- */

/*
* based on
*	https://github.com/jgthms/minireset.css/blob/master/minireset.css
*	https://github.com/alsacreations/KNACSS/blob/master/sass/_vendor/_reboot.scss
*/

/* Set border-box box sizing so borders and padding don't affect set dimensions */
*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Reset block margins so spacing is only applied when needed */
html,
body,
p,
ol,
ul,
li,
blockquote,
figure,
fieldset,
legend,
textarea,
iframe,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
}

/*
	Set html font size to match browser size
	Prevent adjustments of font size after orientation changes on some mobile devices
	Reset line height
*/
html {
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent;
}

/* Reset font sizes so semantic markup doesn't affect styling */
h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: 100%;
	font-weight: normal;
}

/* Prevent `sub` and `sup` elements from affecting the line height in all browsers */
sub,
sup {
	position: relative;
	line-height: 0;
	vertical-align: baseline;
}

/* Remove bullets from unordered lists */
ul {
	list-style: none;
}

/* Remove border from iframes */
iframe {
	border: 0;
}

/* Set responsive media elements so images and embeds scale with browser width */
img,
embed,
object,
audio,
video {
	width: auto;
	max-width: 100%;
	height: auto;
	border: 0;
	vertical-align: bottom;
}

/* Remove default styling of buttons and inputs */
button,
input,
textarea,
select {
	width: auto;
	height: auto;
	resize: none;
	padding: 0;
	border: none;
	border-radius: 0;
	box-shadow: none;
	font-family: inherit;
	font-size: inherit;
	text-align: inherit;
	background-color: transparent;
	background-image: none;
}

/* ====================
   speed up touch delay
   ==================== */
a,
button,
.button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
	touch-action: manipulation;
}

/* Accessibility Settings */
@media (prefers-reduced-motion: reduce) {
	* {
		animation-duration: 0s !important;
		transition-duration: 0s !important;
	}
}

/* Reset tables so tabular data only takes the space it needs */
table {
	border-collapse: collapse;
	border-spacing: 0;
}

td,
th {
	padding: 0;
	text-align: left;
}
